import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import {
  APPLE_STORE_LINK,
  GOOGLE_PLAYSTORE_LINK
} from '../../../utils/helpers/download-link-urls';

export default function DownloadAppCTAGVSU({ containerStyles = '' }) {
  const { appleStoreImg, googlePlayImg } = useStaticQuery(graphql`
        query GVSUHeroQuery {
            googlePlayImg: file(name: { eq: "google-play" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
            appleStoreImg: file(name: { eq: "apple-store" }) {
                childImageSharp {
                    gatsbyImageData(height: 55, quality: 100)
                }
            }
        }
    `);

  return (
    <div
      className={`${containerStyles} flex mb-4 gap-4 max-h-[55px] w-full`}
    >
      <div className='w-32 sm:w-44 overflow-hidden'>
        <a
          href={GOOGLE_PLAYSTORE_LINK}
          target='_blank'
          id='google-store-download-link'
        >
          <GatsbyImage
            image={getImage(googlePlayImg)}
            alt='google play download button'
            imgStyle={{ objectFit: 'contain' }}
          />
        </a>
      </div>
      <div className='w-[115px] sm:w-40 overflow-hidden'>
        <a
          href={APPLE_STORE_LINK}
          target='_blank'
          id='apple-store-download-link'
        >
          <GatsbyImage
            image={getImage(appleStoreImg)}
            alt='apple store download button'
            imgStyle={{ objectFit: 'contain' }}
          />
        </a>
      </div>
    </div>
  );
}
