import React from 'react';
import { graphql } from 'gatsby';

import BaseLayout from '../../components/templates/BaseLayout';
import PrimaryHeader from '../../components/atoms/typography/PrimaryHeader';
import SubHeader from '../../components/atoms/typography/SubHeader';
import Headline from '../../components/atoms/typography/Headline';
import Spacer from '../../components/atoms/utility/Spacer';
import SEO from '../../components/atoms/utility/seo';
import JumboPanel from '../../components/atoms/JumboPanel';
import DownloadAppCTAGVSU from '../../components/atoms/callouts/DownloadAppCTAGVSU';
import Paragraph from '../../components/atoms/typography/Paragraph';

const marketplaceFeatures = {
  ['Compete.']: [
    {
      name: 'Experience More',
      description:
        'The flockx app is packed with features to help you stay connected to your community. So, if you are looking for a way to stay in the know about Grand Rapids, flockx is the perfect app for you.'
    },
    {
      name: 'Engage With Other Students',
      description:
        'Whether you are looking for live music, the best spot to go camping or the best restaurant to grab a slice of pizza, flockx can help you find hotspots and find where these communities are hanging out.'
    }
  ],
  ['Discover.']: [
    {
      name: 'Rediscover The Area',
      description:
        'Allendale holds more hidden gems than meets the eye. See events happening in real-time and join in on the fun.'
    },
    {
      name: 'Curated for GVSU',
      description:
        'Join communities of students specific to the area, such as Griffins hockey fans, pizza lovers, cyclists, backpackers and so many other communities.'
    }
  ],
  ['Level Up.']: [
    {
      name: 'Community Reputation',
      description:
        'Share cool spots and the memories you’ve had with your communities to increase your clout.'
    },
    {
      name: 'Community Rating',
      description:
        'Rate your memories using ratings that matter to your community to help community members grow.'
    },
    {
      name: 'Community Support',
      description:
        'Help new community members learn about your community and where to get started.'
    }
  ]
};

export default function GVSUPage({
                                   data: { gvsuHeroImg, gvsuMobileHeroImg, buildImg, growImg, openGraphImg }
                                 }) {
  const jumboPanelOne = {
    image: {
      imageSrc: buildImg,
      imageAlt: 'friends having a pizza party and having fun'
    },
    headline: {},
    paragraph: {},
    list: {
      name: 'Find Your Community', //change name for section
      listContent: marketplaceFeatures['Compete.'],
      headerColor: '#7593FF'
    }
  };

  const jumboPanelTwo = {
    image: {
      imageSrc: growImg,
      imageAlt:
        'group hiking with hikers silhouettes backdropped by the sunset on the horizon'
    },
    headline: {},
    paragraph: {},
    list: {
      name: 'Explore GVSU',
      listContent: marketplaceFeatures['Discover.'],
      headerColor: '#63D2E1'
    }
  };

  return (
    <BaseLayout
      heroContent={gvsuPageHeroContent}
      heroImg={gvsuHeroImg}
      mobileHeroImg={gvsuMobileHeroImg}
    >
      <SEO
        title='Grand Valley State University | Find Things To Do'
        keywords={[
          'Grand Valley State University',
          'GVSU',
          'find things to do',
          'communities',
          'allendale',
          'grand rapids',
          'michigan',
          'flockx mobile app',
          'hidden gems',
          'pizza'
        ]}
        customSeoImage={openGraphImg}
        description={`Never miss out on what happening around Grand Valley State University. Donwload flockx today to stay connected to the pulse of the community.`}
      />
      <Spacer />
      <Spacer className='block lg:hidden' />
      <JumboPanel content={jumboPanelOne} />
      <Spacer />
      <Spacer className='block md:hidden' />
      <JumboPanel content={jumboPanelTwo} imageSide='left' />
      <Spacer />
      <Spacer className='block lg:hidden' />
      <div className='mx-auto text-left sm:text-center max-w-3xl mb-4 lg:mb-12'>
        <Headline
          spanText='Ready for a new way to explore?'
          spanColor='#000'
          className='mx-auto text-center max-w-[350px] md:max-w-xl mb-4 lg:mb-12 capitalize'
        />
        <div className='relative z-10 text-center'>
          <DownloadAppCTAGVSU containerStyles='justify-center' />
        </div>
      </div>
      <Spacer />
      <Spacer className='block lg:hidden' />
    </BaseLayout>
  );
}

function gvsuPageHeroContent() {
  return (
    <div className='-mt-[15%] sm:mt-0'>
      <PrimaryHeader
        headerText='Find Things to do Around Grand Valley State University'
        className=' mb-2 sm:mb-4 md:mb-8 lg:mb-8 text-white capitalize font-medium max-w-[390px] sm:max-w-none md:max-w-4xl lg:max-w-5xl lg:mr-auto lg:ml-0'
      />
      <SubHeader
        headerText='Stay connected to the pulse of your local community'
        className='mb-4 md:mb-8 lg:mb-10 xl:mb-12 text-white font-light max-w-xs sm:max-w-sm lg:max-w-4xl sm:mx-0'
      />
      <DownloadAppCTAGVSU containerStyles='justify-start' />
      <p className='block sm:pt-0 font-light sm:text-lg md:text-xl lg:text-2xl text-white'>
        Available in the US
      </p>
    </div>
  );
}

export const query = graphql`
    query GVSULPageQuery {
        gvsuHeroImg: file(name: { eq: "gvsu-hero" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        gvsuMobileHeroImg: file(name: { eq: "gvsu-mobile-hero" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        buildImg: file(name: { eq: "find-your-community" }) {
            childImageSharp {
                gatsbyImageData(width: 889)
            }
        }
        growImg: file(name: { eq: "explore-gvsu" }) {
            childImageSharp {
                gatsbyImageData(width: 889)
            }
        }
        openGraphImg: file(name: { eq: "gvsu-thumbnail" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
    }
`;
